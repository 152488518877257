<template>
    <span class="date-format">{{getTime(date)}}</span>
</template>

<script>
import moment from 'moment'

export default {
    name: 'DateFormat',
    props: ['date', 'format'],
    methods: {
        getTime(date) {
            if(!date) return ''
            return moment(new Date(date.toDate ? date.toDate() : date)).format(this.format || 'MMMM DD, YYYY')
        },
    }
}
</script>